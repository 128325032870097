import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

/**
 * `Footer` es un componente de React que representa el pie de página de la aplicación.
 *
 * El componente muestra información de contacto, enlaces a políticas y entidades reguladoras, así como logotipos de estas entidades.
 * Está diseñado para proporcionar información adicional a los usuarios y enlaces importantes relacionados con la empresa y sus regulaciones.
 *
 * Estado:
 * - Este componente no utiliza estado interno.
 *
 * Métodos:
 * - No se definen métodos específicos en este componente.
 *
 * Ejemplo de uso:
 * ```jsx
 * <Footer />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `Footer.css`.
 *
 * @component
 */

const Footer = () => {
  const linkStyle = {
    color: "inherit",
    textDecoration: "none",
  };

  return (
    <footer>
      <div className="footer-section">
        <p>El de Fiar SDLTS SA de CV SOFOM ENR</p>
        <div className="footer-logo">
          <img src="./images/buro.svg" alt="Buró de Entidades Financieras" />
          <img src="./images/cnbv.svg" alt="CNBV" />
          <img src="./images/condusef.svg" alt="CONDUSEF" />
        </div>
      </div>
      <div className="footer-section">
        <Link style={linkStyle} to="/aviso-de-privacidad">
          <p>Aviso de Privacidad</p>
        </Link>
        <Link style={linkStyle} to="/buro-entidades-financieras">
          <p>Buró de Entidades Financieras</p>
        </Link>
        <Link style={linkStyle} to="/une">
          <p>UNE Unidad Especializada de Atención</p>
        </Link>
      </div>
      <div className="footer-section">
        <p>
          Av. P.º de la Reforma 2654, Lomas Altas, Miguel Hidalgo, 11950 Ciudad
          de México, CDMX.
        </p>
        <p>55 94 09 64 83 / 84</p>
        <p>55 15 64 33 13</p>
      </div>
    </footer>
  );
};

export default Footer;
