/**
 * Envía los datos del formulario al servidor mediante una solicitud HTTP POST.
 *
 * @param {Object} data - Datos del formulario a enviar.
 * @param {string} data.nombre_completo - Nombre completo del solicitante.
 * @param {string} data.correo - Correo electrónico del solicitante.
 * @param {string} data.telefono - Número de teléfono del solicitante.
 * @param {string} data.monto - Monto solicitado para el préstamo.
 * @param {string} data.tipo_credito - Tipo de crédito solicitado (personal, hipotecario, automotriz).
 *
 * @returns {Promise<Object>} - Una promesa que se resuelve con la respuesta del servidor en formato JSON.
 *
 * @throws {Error} - Lanza un error si la respuesta del servidor no es satisfactoria (status HTTP no 2xx).
 *
 */
export async function sendEmail(data) {
  const response = await fetch("/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
}
