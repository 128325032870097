import React from "react";
import "./HeroBannerVideo.css";

/**
 * `HeroBannerVideo` es un componente de React que representa un hero banner con un video de fondo.
 * Este componente muestra un video de fondo que se reproduce automáticamente, junto con un título, un subtítulo y un botón de enlace.
 *
 * Props:
 * - `subtitle` (string): El subtítulo que se mostrará en el banner.
 * - `title` (string): El título principal que se mostrará en el banner.
 * - `videoUrl` (string): La URL del video que se reproducirá en el fondo.
 *
 * Ejemplo de uso:
 * ```jsx
 * <HeroBannerVideo
 *   subtitle="Bienvenido a nuestra plataforma"
 *   title="Obtén tu crédito hoy"
 *   videoUrl="https://example.com/video.mp4"
 * />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `HeroBannerVideo.css`.
 *
 * @component
 */

const HeroBannerVideo = ({ subtitle, title, videoUrl }) => {
  return (
    <div className="hero-banner-video">
      <video autoPlay muted loop id="hero-video">
        <source src={videoUrl} type="video/mp4" />
        Tu navegador no soporta videos HTML5.
      </video>
      <div className="hero-content">
        <h2>{subtitle}</h2>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default HeroBannerVideo;
