import React from "react";
import HeroBannerImage from "../HeroBanner/HeroBannerImage";
import ContactForm from "../ContactForm/ContactForm";
import "./Nosotros.css"

const Nosotros = () => {
  return (
    <div>
      <HeroBannerImage
        subtitle=""
        title="Nosotros"
        // buttonText="Solicita Tu Crédito"
        imageUrl="./images/nosotros.jpg"
      />

      <div className="text-container" style={{ margin: "25px 75px" }}>
        <h3 style={{ textAlign: "center", marginBottom: "5px" }}>
          ¿Quiénes Somos?
        </h3>
        <p style={{ margin: "10px 0" }}>
          En El de Fiar, somos una Institución Financiera Mexicana con más de
          una década de experiencia en el Sector Financiero del país. Desde
          nuestro inicio, nos hemos comprometido a ofrecer productos financieros
          de la más alta calidad, adaptados a las necesidades de nuestros
          clientes. Nuestra trayectoria y dedicación son prueba de nuestro
          compromiso continuo con la excelencia y el bienestar económico de
          quienes confían en nosotros.
        </p>
      </div>

      <div className="container-mvv">
        <div className="container-vision">
          <h4>Visión</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderBottom: "2px solid green",
                width: "30%",
                height: "5px",
                margin: "15px 0px 25px 0px",
              }}
            ></div>
          </div>
          <p>
            Nuestra visión está guiada por los principios de Innovación,
            Objetividad, Responsabilidad, Honestidad, Eficiencia y
            Transparencia. Buscamos transformar el panorama financiero con
            soluciones creativas y efectivas, manteniendo siempre un enfoque
            claro y justo en todas nuestras operaciones.
          </p>
        </div>
        <div className="container-mision">
          <h4>Misión</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderBottom: "2px solid #ffb200",
                width: "30%",
                height: "5px",
                margin: "15px 0px 25px 0px",
              }}
            ></div>
          </div>
          <p>
            Nuestra misión es consolidarnos como la empresa líder dentro del
            Sector Financiero Mexicano. Nos esforzamos por brindar un servicio
            excepcional que no solo cumpla, sino que supere las expectativas de
            nuestros clientes, contribuyendo al crecimiento económico y al
            desarrollo financiero del país.
          </p>
        </div>
        <div className="container-valores">
          <h4>Valores</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderBottom: "2px solid #004FAB",
                width: "30%",
                height: "5px",
                margin: "15px 0px 25px 0px",
              }}
            ></div>
          </div>
          <p>
            En El de Fiar, nos enorgullece apoyar a nuestros clientes en el
            ámbito económico mediante créditos personales y empresariales
            diseñados para satisfacer sus necesidades. Nos comprometemos a
            cumplir con los más altos estándares de calidad y responsabilidad
            social.
          </p>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Nosotros;
