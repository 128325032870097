import React, { useState, useEffect } from "react";
import "./Slideshow.css";

/**
 * `Slideshow` es un componente de React que muestra una presentación de diapositivas con imágenes que cambian automáticamente.
 *
 * El componente presenta una serie de diapositivas que se alternan automáticamente cada 6 segundos. Además, proporciona puntos de navegación que permiten a los usuarios cambiar manualmente a una diapositiva específica.
 *
 * Estado:
 * - `slideIndex` (number): El índice de la diapositiva actualmente visible.
 *
 * Métodos:
 * - `showSlides`: Muestra la diapositiva correspondiente al índice proporcionado y actualiza los puntos de navegación para reflejar la diapositiva activa.
 * - `currentSlide`: Cambia la diapositiva actual al índice especificado por el usuario al hacer clic en un punto de navegación.
 *
 * Efectos:
 * - Se configura un intervalo para cambiar automáticamente a la siguiente diapositiva cada 6 segundos. Este intervalo se limpia cuando el componente se desmonta.
 *
 * Ejemplo de uso:
 * ```jsx
 * <Slideshow />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `Slideshow.css`.
 *
 * @component
 */

const Slideshow = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const showSlides = (index) => {
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("dot");

    if (index > slides.length) setSlideIndex(1);
    if (index < 1) setSlideIndex(slides.length);

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }

    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
  };

  useEffect(() => {
    showSlides(slideIndex);

    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        if (newIndex > 2) return 1;
        return newIndex;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [slideIndex]);

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  return (
    <div className="slideshow-container">
      <h2>Nuestras Marcas</h2>
      <div className="mySlides fade">
        <img src="./images/brands-one.png" alt="Slide 1" style={{ width: "100%" }} />
      </div>

      <div className="mySlides fade">
        <img src="./images/brands-two.png" alt="Slide 2" style={{ width: "100%" }} />
      </div>

      <div style={{ textAlign: "center" }}>
        <span className="dot" onClick={() => currentSlide(1)}></span>
        <span className="dot" onClick={() => currentSlide(2)}></span>
      </div>
    </div>
  );
};

export default Slideshow;
