import React from "react";
import ContactForm from "../ContactForm/ContactForm";
// import CarGallery from "../CardCar/CarGallery";
import HeroBannerVideo from "../HeroBanner/HeroBannerVideo";
import CarSimulator from "../CarSimulator/CarSimulator";


const Arrendamiento = () => {
  return (
    <div>
      <HeroBannerVideo
        subtitle=""
        title="Arrendamiento"
        videoUrl="./videos/gac-gn8.mp4"
      />
      {/* <CarGallery/> */}
      <CarSimulator/>
      <ContactForm/>
    </div>
  );
};

export default Arrendamiento;
