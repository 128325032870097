import React from 'react';
import ContactForm from '../ContactForm/ContactForm';
import HeroBannerImage from '../HeroBanner/HeroBannerImage';
import Simulator from '../Simulator/Simulator';

const CreditosEmpresariales = () => {
  return (
    <div>
      <HeroBannerImage
        style={{

        }}
        subtitle=""
        title="Créditos Empresariales"
        imageUrl="./images/creditos-empresariales.jpg"
      />
      <Simulator/>
      <ContactForm />
    </div>
  );
};

export default CreditosEmpresariales;
