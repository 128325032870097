import React from "react";
import { HashLoader } from "react-spinners";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <HashLoader 
        color="#ffb200"
        speedMultiplier="1.5"
      />
    </div>
  );
};

export default LoadingScreen;
