import React, { useState } from "react";
import "./Simulator.css";

/**
 * `Simulator` es un componente de React que permite a los usuarios simular el costo de un préstamo en función del monto solicitado y el número de quincenas para el pago.
 *
 * El componente utiliza un formulario para capturar la cantidad de dinero requerida y el plazo en quincenas. Al enviar el formulario, calcula el pago quincenal estimado para tres tasas de interés diferentes y muestra los resultados.
 *
 * Estado:
 * - `amount` (string): La cantidad de dinero que el usuario desea solicitar.
 * - `terms` (string): El número de quincenas en el que el usuario planea pagar el préstamo.
 * - `results` (object): Los resultados de los pagos quincenales calculados para tres tasas de interés diferentes.
 *
 * Métodos:
 * - `handleSubmit`: Calcula el pago quincenal basado en el monto y el plazo proporcionados. Actualiza el estado `results` con los valores calculados.
 *
 * Ejemplo de uso:
 * ```jsx
 * <Simulator />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `Simulator.css`.
 *
 * @component
 */

const Simulator = () => {
  const aStyle = {
    color: "inherit",
    textDecoration: "underline",
    fontWeight: "700"
  };
  const linkStyle = {
    color: "#ffb200",
    textDecoration: "underline",
  };

  const [amount, setAmount] = useState("");
  const [terms, setTerms] = useState("");
  const [results, setResults] = useState({
    one: "",
    two: "",
    three: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const monto = parseFloat(amount);
    const plazos = parseInt(terms, 10);

    if (isNaN(monto) || isNaN(plazos) || plazos === 0) return;

    const pago1 = monto / plazos + (monto * 0.0198) / 2;
    const pago2 = monto / plazos + (monto * 0.0298) / 2;
    const pago3 = monto / plazos + (monto * 0.0398) / 2;

    const formatNumber = (number) => number.toLocaleString();

    setResults({
      one: `$${formatNumber(Math.round(pago1))}`,
      two: `$${formatNumber(Math.round(pago2))}`,
      three: `$${formatNumber(Math.round(pago3))}`,
    });
  };

  return (
    <div className="container-simulator">
      <div className="simulator-content">
        <div className="simulator-text">
          <form id="formularioPrestamo" onSubmit={handleSubmit}>
            <h2>Simula Tu Préstamo</h2>
            <p>Nos ajustamos a tus necesidades</p>
            <label htmlFor="monto">¿Cuánto dinero necesitas?</label>
            <input
              type="number"
              id="monto"
              name="monto"
              step="1000.00"
              placeholder="$"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />

            <select
              id="plazos"
              name="plazos"
              value={terms}
              onChange={(e) => setTerms(e.target.value)}
              required
            >
              <option value="">¿A cuántas quincenas quieres pagarlo?</option>
              <option value="9">9</option>
              <option value="12">12</option>
              <option value="18">18</option>
            </select>
            <input type="submit" className="btn-secondary" value="Cotizar" />
            <p
              style={{
                marginTop: "10px",
              }}
            >
              Si ya tienes un convenio con nosotros,{" "}
              <a style={aStyle} href="https://signup.metamap.com/?merchantToken=628bb05bc5007d001b7108ab&flowId=65f32d9689eb89001c567e2b" target="_blank" rel="noopener noreferrer">
                completa tu información aquí
              </a>
              .
            </p>
          </form>
        </div>
        <div className="simulator-info">
          <div className="column-one">
            <div className="one"></div>
            <div className="two">Nuestra mejor tasa</div>
            <div className="three">Nuestra tasa promedio</div>
            <div className="four">Tasa SOFOMES</div>
          </div>
          <div className="column-two">
            <div className="one">Tasa de interés mensual:</div>
            <div className="two">1.98%</div>
            <div className="three">2.98%</div>
            <div className="four">3.98%</div>
          </div>
          <div className="column-three">
            <div className="one">Pago quincenal:</div>
            <div className="two">
              <p>
                <strong style={linkStyle}>{results.one}</strong>
              </p>
            </div>
            <div className="three">
              <p>{results.two}</p>
            </div>
            <div className="four">
              <p>{results.three}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simulator;
