import React, { useState } from "react";
import "./CarSimulator.css";

const Simulator = () => {
  const [selectedCar, setSelectedCar] = useState(null);
  const [mensualidades, setMensualidades] = useState("");
  const [enganche, setEnganche] = useState("");
  const [results, setResults] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [carTypes, setCarTypes] = useState({
    carTypeOne: "",
    carTypeTwo: "",
    carTypeThree: "",
    carTypeFour: "",
  });

  const carPrices = {
    "car-gs8": [699900, 759900, 819900, 869900],
    "car-emzoom": [468900, 479900, 489200],
    "car-gn8": [888800, 1028800],
  };

  const carTypeNames = {
    "car-gs8": ["GL FWD", "GT FWD", "GT AWD", "GX AWD"],
    "car-emzoom": ["GL", "GL+", "GL Lux", ""],
    "car-gn8": ["GT", "GT Lux", "", ""],
  };

  const handleCarClick = (carId) => {
    setSelectedCar(carId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedCar || !mensualidades || !enganche) return;

    const carPriceList = carPrices[selectedCar];
    const carTypeList = carTypeNames[selectedCar];

    const tasaAnual = 0.015;

    const pv = (carPrice, enganche) => carPrice * (1 - enganche / 100);
    const fv = (carPrice) => carPrice * 0.5;
    const calcularPagoMensual = (pv, fv, tasaAnual, mensualidades) => {
      const r = tasaAnual;
      const denominador = (1 - Math.pow(1 + r, -mensualidades)) / r;
      const numeradorAjustado = pv - fv / Math.pow(1 + r, mensualidades);
      const P = (numeradorAjustado / denominador) * 1.16;
      return P;
    };
    const agregarComa = (numero) =>
      numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const resultados = carPriceList.map((price) => {
      const pvValue = pv(price, enganche);
      const fvValue = fv(price);
      return calcularPagoMensual(pvValue, fvValue, tasaAnual, mensualidades);
    });

    const formattedResults = resultados.map(
      (result) => `$${agregarComa(Math.round(result))}`
    );
    const emptyResults = Array(carPriceList.length).fill("");

    setResults({
      one: formattedResults[0] || emptyResults[0],
      two: formattedResults[1] || emptyResults[1],
      three: formattedResults[2] || emptyResults[2],
      four: formattedResults[3] || emptyResults[3],
    });

    setCarTypes({
      carTypeOne: carTypeList[0] || "",
      carTypeTwo: carTypeList[1] || "",
      carTypeThree: carTypeList[2] || "",
      carTypeFour: carTypeList[3] || "",
    });
  };

  return (
    <div className="container-simulator-car">
      <form id="formularioPrestamo" onSubmit={handleSubmit}>
        <h1>Simula Tu Préstamo</h1>
        <p>Nos ajustamos a tus necesidades</p>
        <div className="container-cars">
          <div
            className={`car ${selectedCar === "car-gs8" ? "selected" : ""}`}
            id="car-gs8"
            onClick={() => handleCarClick("car-gs8")}
          >
            <img src="./images/gs8.png" alt="GS8" />
          </div>
          <div
            className={`car ${selectedCar === "car-emzoom" ? "selected" : ""}`}
            id="car-emzoom"
            onClick={() => handleCarClick("car-emzoom")}
          >
            <img src="./images/enzoom.png" alt="EMZOOM" />
          </div>
          <div
            className={`car ${selectedCar === "car-gn8" ? "selected" : ""}`}
            id="car-gn8"
            onClick={() => handleCarClick("car-gn8")}
          >
            <img src="./images/gn8.png" alt="GN8" />
          </div>
        </div>

        <select
          id="mensualidades"
          name="mensualidades"
          value={mensualidades}
          onChange={(e) => setMensualidades(e.target.value)}
          required
        >
          <option value="">¿A cuántas mensualidades quieres pagarlo?</option>
          <option value="24">24</option>
          <option value="36">36</option>
          <option value="48">48</option>
        </select>

        <select
          id="enganche"
          name="enganche"
          value={enganche}
          onChange={(e) => setEnganche(e.target.value)}
          required
        >
          <option value="">Enganche</option>
          <option value="10">10%</option>
          <option value="20">20%</option>
          <option value="30">30%</option>
        </select>

        <input className="btn-secondary" type="submit" value="Cotizar" />
      </form>

      <h3 id="pagoMensual">Pago Mensual</h3>
      {/* <div className="simulator-info-car">
        <div className="column-one-car">
          <div className="one">&nbsp;</div>
          <div className="two" id="carTypeOne">
            {carTypes.carTypeOne}
          </div>
          <div className="three" id="carTypeTwo">
            {carTypes.carTypeTwo}
          </div>
          <div className="four" id="carTypeThree">
            {carTypes.carTypeThree}
          </div>
          <div className="four" id="carTypeFour">
            {carTypes.carTypeFour}
          </div>
        </div>
        <div className="column-two-car">
          <div className="one">&nbsp;</div>
          <div className="two" id="one">
            {results.one}
          </div>
          <div className="three" id="two">
            {results.two}
          </div>
          <div className="four" id="three">
            {results.three}
          </div>
          <div className="four" id="four">
            {results.four}
          </div>
        </div>
      </div> */}
      <div className="container-simulator-info-car">
        <div className="simulator-content-info-car">
          <div className="simulator-info-car">
              <div className="column-one-car">
              <div className="one">
              <p>&nbsp;</p>
              </div>
              <div className="two" id="carTypeOne">
                <p>{carTypes.carTypeOne}</p>
                <p>{results.one}</p>
              </div>
              <div className="three" id="carTypeTwo">
                <p>{carTypes.carTypeTwo}</p>
                <p>{results.two}</p>
              </div>
              <div className="four" id="carTypeThree">
                <p>{carTypes.carTypeThree}</p>
                <p>{results.three}</p>
              </div>
              <div className="four" id="carTypeFour">
                <p>{carTypes.carTypeFour}</p>
                <p>{results.four}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simulator;
