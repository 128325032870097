import React from "react";
import "./HeroBannerImage.css";

/**
 * `HeroBannerImage` es un componente de React que representa un hero banner con una imagen de fondo.
 * Este componente muestra una imagen de fondo junto con un título y un subtítulo superpuestos.
 *
 * Props:
 * - `subtitle` (string): El subtítulo que se mostrará en el banner.
 * - `title` (string): El título principal que se mostrará en el banner.
 * - `imageUrl` (string): La URL de la imagen que se mostrará en el fondo.
 *
 * Ejemplo de uso:
 * ```jsx
 * <HeroBannerImage
 *   subtitle="Bienvenido a nuestra página"
 *   title="Explora nuestras ofertas"
 *   imageUrl="https://example.com/image.jpg"
 * />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `HeroBannerImage.css`.
 *
 * @component
 */

const HeroBannerImage = ({ subtitle, title, imageUrl }) => {
  return (
    <div className="hero-banner-image">
      <img src={imageUrl} alt={`Logo ${title}`} id="hero-image" />
      <div className="hero-content">
        <h2>{subtitle}</h2>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default HeroBannerImage;
