import React, { useState, useEffect } from "react";
import "./ContactForm.css";
import { sendEmail } from "../../api";

/**
 * `ContactForm` es un componente de React que proporciona un formulario para que los usuarios soliciten un crédito y envíen sus datos de contacto.
 * Además, muestra un mapa de Google Maps con la ubicación de una oficina específica.
 *
 * Estado:
 * - `formData` (object): Un objeto que contiene los datos del formulario, incluyendo `nombre_completo`, `correo`, `telefono`, `monto` y `tipo_credito`.
 * - `error` (string | null): Un mensaje de error si ocurre un problema al enviar el formulario.
 * - `success` (string | null): Un mensaje de éxito cuando el formulario se envía correctamente.
 *
 * Métodos:
 * - `handleChange`: Actualiza el estado `formData` cuando los campos del formulario cambian.
 * - `validateForm`: Verifica si todos los campos del formulario están completos. Retorna un mensaje de error si algún campo está vacío.
 * - `handleSubmit`: Maneja el envío del formulario. Valida los datos, envía el formulario usando `sendEmail`, y maneja los mensajes de error o éxito.
 *
 * Hooks:
 * - `useEffect`:
 *   - Se utiliza para cargar el script de Google Maps y inicializar el mapa con un marcador en la ubicación especificada.
 *   - Se utiliza para limpiar los mensajes de error y éxito después de 2 segundos.
 *
 * Ejemplo de uso:
 * ```jsx
 * <ContactForm />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `ContactForm.css`.
 *
 * @component
 */

function ContactForm() {
  const [formData, setFormData] = useState({
    nombre_completo: "",
    correo: "",
    telefono: "",
    monto: "",
    tipo_credito: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError(null);
        setSuccess(null);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [error, success]);

  useEffect(() => {
    const loadMapScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBKp-vpw9i0NpGISnvZgE9n2uUtWs3obo8&callback=initMap&libraries=marker`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      window.initMap = () => {
        const torreQuadrata = { lat: 19.393124, lng: -99.239168 };

        const map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: 15,
          center: torreQuadrata,
        });

        new window.google.maps.Marker({ position: torreQuadrata, map: map });
      };
    };

    loadMapScript();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (
      !formData.nombre_completo ||
      !formData.correo ||
      !formData.telefono ||
      !formData.monto ||
      !formData.tipo_credito
    ) {
      return "Todos los campos son obligatorios.";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const result = await sendEmail(formData);
      setSuccess(result.message);
      setFormData({
        nombre_completo: "",
        correo: "",
        telefono: "",
        monto: "",
        tipo_credito: "",
      });
    } catch (error) {
      setError(
        "Hubo un error al enviar el correo. Por favor, inténtelo de nuevo."
      );
    }
  };

  return (
    <div id="contactForm" className="contact-container">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="nombre_completo"
            value={formData.nombre_completo}
            onChange={handleChange}
            placeholder="Nombre Completo"
          />
          <input
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
            placeholder="Correo Electrónico"
          />
          <input
            type="tel"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            placeholder="Ej. 5512345678"
          />
          <input
            type="number"
            name="monto"
            value={formData.monto}
            onChange={handleChange}
            placeholder="Monto Solicitado"
          />
          <select
            name="tipo_credito"
            value={formData.tipo_credito}
            onChange={handleChange}
          >
            <option value="">Seleccione el tipo de crédito</option>
            <option value="personal">Crédito Personal</option>
            <option value="hipotecario">Crédito Empresarial</option>
            <option value="automotriz">Crédito Automotriz</option>
          </select>
          <button className="btn" type="submit">
            Solicita Tu Crédito
          </button>

          {error && <div style={{ color: "red" }}>{error}</div>}
          {success && <div style={{ color: "green" }}>{success}</div>}
        </form>
      </div>
      <div id="map">{/* mapa */}</div>
    </div>
  );
}

export default ContactForm;
