import React from "react";
import HeroBannerImage from "../HeroBanner/HeroBannerImage";

const UnidadEspecializada = () => {
  return (
    <div>
      <HeroBannerImage
        subtitle=""
        title="UNIDAD ESPECIALIZADA DE ATENCIÓN A CLIENTES"
        imageUrl="aviso-de-privacidad.jpg"
      />
      <div className="text-container">
        <h3>
          UNIDAD ESPECIALIZADA DE ATENCIÓN A CLIENTES (UNE)
        </h3>
        <p style={{ margin: "10px 0" }}>
          La Unidad Especializada (UNE) tiene como objetivo brindar atención a
          los clientes en forma directa y oportuna. En caso de alguna consulta,
          reclamación o aclaración, podrá realizarla por correo electrónico, vía
          telefónica, o personalmente.
        </p>
        <p style={{ margin: "5px 0" }}>
          <strong>Titular y encargado regional de la UNE:</strong> Blanca
          Xóchitl Muñoz Guerrero
        </p>
        <p style={{ margin: "5px 0" }}>
          <strong>Domicilio:</strong> Av. P.º de la Reforma 2654, Lomas Altas,
          Miguel Hidalgo, 11950 Ciudad de México, CDMX.
        </p>
        <p style={{ margin: "5px 0" }}>
          <strong>Teléfono:</strong> 55 94 09 64 84
        </p>
        <p style={{ margin: "5px 0" }}>
          <strong>Correo electrónico:</strong> une@eldefiar.com
        </p>
        <p style={{ margin: "5px 0" }}>
          <strong>Horario de atención:</strong> Lunes a Viernes de 10:00 a 15:00
          horas.
        </p>
        <p style={{ margin: "10px 0" }}>
          A partir de su recepción en la UNE, usted tendrá respuesta en un plazo
          no mayor a 30 días hábiles.
        </p>
        <p style={{ margin: "10px 0" }}>
          En caso de dudas, quejas, reclamaciones o consultar información sobre
          las comisiones para fines informativos y de comparación, podrá acudir
          a la Comisión Nacional para la Protección y Defensa de los Usuarios de
          Servicios Financieros (CONDUSEF), con domicilio en Insurgentes Sur No.
          762, Colonia del Valle, Delegación Benito Juárez, C.P. 03100, Ciudad
          de México, correo electrónico asesoria@condusef.gob.mx, teléfonos
          01-800-999-8080 y 5340-0999, o consultar la página electrónica en
          internet{" "}
          <a
            href="https://www.condusef.gob.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.condusef.gob.mx
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default UnidadEspecializada;
