import React from "react";
import HeroBannerImage from "../HeroBanner/HeroBannerImage";

const BuroEntidadesFinancieras = () => {
  return (
    <div>
      <HeroBannerImage
        subtitle=""
        title="Buro de Entidades Financieras"
        imageUrl="aviso-de-privacidad.jpg"
      />
      <div className="text-container" style={{ margin: "25px 75px" }}>
        <h3 style={{ textAlign: "center", marginBottom: "5px" }}>
          ¿Qué es el Buró de Entidades Financieras?
        </h3>
        <p style={{ margin: "10px 0" }}>
          Es una herramienta de consulta y difusión con la que podrás conocer
          los productos que ofrecen las entidades financieras, sus comisiones y
          tasas, las reclamaciones de los usuarios, las prácticas no sanas en
          que incurren, las sanciones administrativas que les han impuesto, las
          cláusulas abusivas de sus contratos y otra información que resulte
          relevante para informarte sobre su desempeño.
        </p>
        <p style={{ margin: "10px 0" }}>
          Con el Buró de Entidades Financieras, se logrará saber quién es quién
          en bancos, seguros, sociedades financieras de objeto múltiple, cajas
          de ahorro, afores, entre otras entidades.
        </p>

        <p style={{ margin: "10px 0" }}>
          Con ello, podrás comparar y evaluar a las entidades financieras, sus
          productos y servicios y tendrás mayores elementos para elegir lo que
          más te convenga.
        </p>
        <p style={{ margin: "10px 0" }}>
          Esta información te será útil para elegir un producto financiero y
          también para conocer y usar mejor los que ya tienes.
        </p>
        <p style={{ margin: "10px 0" }}>
          Este Buró de Entidades Financieras, es una herramienta que puede
          contribuir al crecimiento económico del país, al promover la
          competencia entre las instituciones financieras; que impulsará la
          transparencia al revelar información a los usuarios sobre el desempeño
          de éstas y los productos que ofrecen y que va a facilitar un manejo
          responsable de los productos y servicios financieros al conocer a
          detalle sus características.
        </p>
        <p style={{ margin: "10px 0" }}>
          Lo anterior, podrá derivar en un mayor bienestar social, porque al
          conjuntar en un solo espacio tan diversa información del sistema
          financiero, el usuario tendrá más elementos para optimizar su
          presupuesto, para mejorar sus finanzas personales, para utilizar
          correctamente los créditos que fortalecerán su economía y obtener los
          seguros que la protejan, entre otros aspectos.
        </p>
        <h4>¿Quieres saber más?</h4>
        <p style={{ margin: "10px 0" }}>
          Para conocer la información de todo el sector al que corresponden
          estas entidades, se podrá acceder al portal de internet del Buró de
          Crédito
        </p>
        <a href="https://www.buro.gob.mx/" target="_blank" rel="noopener noreferrer">https://www.buro.gob.mx</a>
        <div style={{
          display:"flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "15px 0px"
        }}>
          {/* <img style={{ alignContent: "center", width: "1200px"}} src="buro.png" alt="" />
          <img style={{ alignContent: "center", margin: "25px 25px", width: "400px"}} src="buro-entidades-financieras.jpg" alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default BuroEntidadesFinancieras;
