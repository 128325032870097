import React from "react";
import Card from "./Card";
import "./CardContainer.css";

/**
 * `CardContainer` es un componente de React que actúa como un contenedor para múltiples tarjetas (`Card`).
 * Este componente muestra una serie de tarjetas con una imagen, un título y un texto descriptivo para cada una.
 *
 * Cada tarjeta dentro del contenedor se configura con una imagen, un título y un texto específico que se pasan como props al componente `Card`.
 *
 * Ejemplo de uso:
 * ```jsx
 * <CardContainer />
 * ```
 *
 * Estilos:
 * Los estilos para este componente están definidos en el archivo `CardContainer.css`.
 *
 * @component
 */

export default function CardContainer() {
  return (
    <div className="card-container">
      <Card
        image={"./images/creditos-personales-card.png"}
        title={"Créditos Personales"}
        text={
          "Créditos personales con tasas competitivas a plazos que se ajustan a tus necesidades."
        }
      />
      <Card
        image={"./images/arrendamiento-card.png"}
        title={"Arrendamiento"}
        text={
          "Consigue el auto que necesitas con nuestros planes flexibles. ¡Haz clic aquí y arrenda tu auto hoy!."
        }
      />
      <Card
        image={"/images/creditos-empresariales-card.png"}
        title={"Créditos Empresariales"}
        text={
          "Impulsa tu negocio con nuestros créditos empresariales. Obtén hasta 30 millones de pesos en 48 horas."
        }
      />
    </div>
  );
}
