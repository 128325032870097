import React from "react";
import Simulator from "../Simulator/Simulator";
import HeroBannerImage from "../HeroBanner/HeroBannerImage";
import ContactForm from "../ContactForm/ContactForm";

const CreditosPersonales = () => {
  return (
    <div>
      <HeroBannerImage
        subtitle=""
        title="Créditos Personales"
        imageUrl="./images/creditos-personales.jpg" //
      />
      <Simulator />
      <ContactForm />
    </div>
  );
};

export default CreditosPersonales;
