import React from "react";
import HeroBannerImage from "../HeroBanner/HeroBannerImage";
import "./AvisoPrivacidad.css";

const AvisoPrivacidad = () => {
  return (
    <div>
      <HeroBannerImage
        subtitle=""
        title="Aviso de Privacidad"
        imageUrl="./images/aviso-de-privacidad.avif"
      />
      <div className="text-container">
        <h3>Aviso de Privacidad para Visitantes del Sitio Web</h3>
        <h4>1. Identidad y Domicilio del Responsable</h4>
        <p>
          EL DE FIAR SDLTS, S.A. de C.V. S.O.F.O.M. E.N.R. (en adelante, “EL DE
          FIAR”) es el responsable del tratamiento de sus datos personales.
          Nuestra dirección es Avenida Paseos de la Reforma, Número 2654, Piso
          13, Colonia Lomas Altas, Alcaldía Miguel Hidalgo, Ciudad de México,
          C.P. 11950. Cumplimos con la Ley Federal de Protección de Datos
          Personales en Posesión de Particulares y su Reglamento, garantizando
          el uso, protección y confidencialidad de sus datos personales.
        </p>
        <h4>2. Finalidades del Tratamiento de Datos Personales</h4>
        <p>Los datos personales que recabamos se utilizan para:</p>
        <ol>
          <li>
            Integrar nuestra base de datos de usuarios que solicitan la
            aprobación de crédito.
          </li>
          <li>Verificar el historial crediticio del solicitante.</li>
          <li>Enviar información relacionada con su solicitud de crédito.</li>
        </ol>
        <p>
          Si desea limitar el uso o divulgación de sus datos personales, puede
          hacerlo enviando una solicitud por escrito al correo electrónico:
          legal@eldefiar.com.
        </p>
        <h4>3. Datos Personales Recabados</h4>
        <p>
          Para cumplir con las finalidades mencionadas, podemos recabar la
          siguiente información:
        </p>
        <ol>
          <li>Apellidos y nombre(s).</li>
          <li>Fecha de nacimiento.</li>
          <li>
            Números de teléfono (incluyendo clave de larga distancia y, en su
            caso, extensión).
          </li>
          <li>Correo electrónico.</li>
          <li>Domicilio.</li>
        </ol>
        <h4>4. Transferencia de Datos Personales</h4>
        <p>Sus datos personales pueden ser transferidos a:</p>
        <ol>
          <li>
            Sociedades subsidiarias, afiliadas o controladoras de EL DE FIAR.
          </li>
          <li>
            Terceros no relacionados con EL DE FIAR que puedan participar en el
            resguardo de información, aprobación y otorgamiento de crédito, o
            que necesiten procesar la información por cuenta del responsable.
          </li>
        </ol>
        <p>
          Estos terceros están comprometidos a mantener la confidencialidad de
          sus datos personales y cumplir con este Aviso de Privacidad.
        </p>
        <h4>5. Consentimiento</h4>
        <p>
          Al enviar sus datos personales a través de nuestro sitio web, usted
          acepta que EL DE FIAR realice el tratamiento y transferencia de dichos
          datos conforme a este Aviso de Privacidad.
        </p>
        <h4>6. Mecanismos para Conocer el Aviso de Privacidad</h4>
        <p>
          Para conocer el contenido del Aviso de Privacidad Integral, puede
          enviar una solicitud al correo: legal@eldefiar.com, o acudir a
          nuestras instalaciones. Nos comprometemos a informarle sobre cualquier
          cambio en este aviso a través de nuestra página web. Al
          proporcionarnos sus datos personales, usted acepta el tratamiento de
          los mismos conforme a los términos establecidos en este Aviso de
          Privacidad. Si no está de acuerdo con estas condiciones, le pedimos
          que no envíe su información.
        </p>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;
